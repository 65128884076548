<template>
    <mercur-dialog :is-open.sync="localDialogActive">
        <div slot="header"><h2 class="font-weight-normal">Factor configuration</h2></div>
        <div slot="default">
            <pretty-select
                class="mb-3"
                data-test="factorConfigurationPopupSupplierSelect"
                placeholder="Supplier"
                label="supplierName"
                :options="availableSuppliers"
                v-model="$v.details.supplierId.$model"
                :reduce="supplier => supplier.supplierId"
                required
            ></pretty-select>
            <span class="error pretty-select-error" v-if="$v.details.supplierId.$error">
                Supplier is required
            </span>

            <pretty-select
                class="mb-3"
                placeholder="Service level"
                data-test="factorConfigurationPopupServiceLevelSelect"
                label="serviceLevelName"
                v-if="availableServiceLevels"
                :options="availableServiceLevels"
                :reduce="serviceLevel => serviceLevel.serviceLevelId"
                v-model="$v.details.serviceLevelId.$model"
                :select-on-tab="true"
                @keypress.enter.prevent="() => {}"
            ></pretty-select>

            <div class="row align-items-center">
                <div class="col-6">
                    <pretty-select
                        placeholder="Product name"
                        label="productConfigurationName"
                        data-test="factorConfigurationPopupProductNameSelect"
                        v-if="supplierProducts"
                        :options="supplierProducts"
                        :getOptionKey="product => product ? product.indexPosition : 0"
                        v-model="selectedProduct"
                        :on-change="setSelectedProduct(selectedProduct)"
                        :select-on-tab="true"
                        @keypress.enter.prevent="() => {}"
                    ></pretty-select>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.details.productHash.$model"
                        data-test="factorConfigurationPopupProductHashInput"
                        :class="{'form-invalid': $v.details.productHash.$error}">
                        Product hash
                    </mercur-input>
                </div>
            </div>

            <pretty-select
                class="mb-3"
                placeholder="Country"
                label="countryName"
                data-test="factorConfigurationPopupCountrySelect"
                :options="availableCountries"
                :reduce="country => country.countryShortCode"
                v-model="$v.details.countryCode.$model"
                :select-on-tab="true"
                required
                @keypress.enter.prevent="() => {}"
            ></pretty-select>
            <span class="error pretty-select-error" v-if="$v.details.countryCode.$error">
                Country is required
            </span>

            <mercur-input
                v-model.number="$v.details.supplierFactor.$model"
                data-test="factorConfigurationPopupSupplierFactorInput"
                type="number"
                :class="{'form-invalid': $v.details.supplierFactor.$error}">
                Factor
                <template slot="note">
                    <span class="form-error" v-if="!$v.details.supplierFactor.required">Required</span>
                </template>
            </mercur-input>
        </div>
        <div slot="footer">
            <mercur-button class="btn text-uppercase" @click="localDialogActive = false">
                Close
            </mercur-button>
            <mercur-button data-test="factorConfigurationPopupSupplierSaveBtn" class="btn btn-raised btn-yellow text-uppercase" @click="saveFactorConfiguration" :disabled="$v.$invalid || loading">
                Save
            </mercur-button>
        </div>
        <mercur-progress-bar indeterminate v-if="loading || productsLoading"></mercur-progress-bar>
    </mercur-dialog>
</template>

<script>
import regions from 'country-region-data'
import { validationMixin } from 'vuelidate'
import {
    required,
} from 'vuelidate/lib/validators'
import CONFIG from '@root/config'
import PrettySelect from '../utils/PrettySelect'

export default {
    name: 'FactorConfigurationPopup',
    props: ['dialogActive', 'factorDetails', 'availableServiceLevels', 'availableSuppliers'],
    components: { PrettySelect },
    mixins: [validationMixin],
    data () {
        return {
            availableCountries: regions,
            loading: false,
            productsLoading: false,
            details: null,
            supplierProducts: [],
            selectedProduct: '',
        }
    },
    computed: {
        localDialogActive: {
            get () {
                return this.dialogActive
            },
            set (value) {
                this.$emit('update:dialogActive', value)
            },
        },
    },
    validations: {
        details: {
            supplierId: {
                required,
            },
            serviceLevelId: {

            },
            countryCode: {
                required,
            },
            productHash: {

            },
            supplierFactor: {
                required,
            },
        },
    },
    methods: {
        setSelectedProduct (product) {
            this.$v.details.productHash.$model = product.productHash
        },
        saveFactorConfiguration () {
            ['supplierId', 'serviceLevelId', 'countryCode', 'productHash'].forEach(key => {
                if (this.details[key] === '') {
                    this.details[key] = null
                }
            })

            let url = CONFIG.API.ROUTES.FACTORS.ADD
            if (this.details.supplierFactorId) {
                url = CONFIG.API.ROUTES.FACTORS.UPDATE.replace('{supplierFactorId}', this.details.supplierFactorId)
            }
            this.addJob(url)
            this.loading = true

            this.$api.post(url, this.details).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Factor configuration successfully saved.`,
                })
                this.$emit('updated', this.details)
                this.localDialogActive = false
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving Factor configuration failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },

        handleDialogOpened () {
            this.$set(this, 'details', {
                supplierId: null,
                serviceLevelId: null,
                productHash: null,
                countryCode: null,
                supplierFactor: 1,
                ...this.factorDetails,
            })
            this.$v.$reset()
        },
    },
    watch: {
        factorDetails: {
            handler () {
                this.handleDialogOpened()
            },
            deep: true,
            immediate: true,
        },
        'details.supplierId' (supplierId) {
            if (!supplierId) {
                return
            }
            this.$set(this, 'supplierProducts', [])
            const productsParams = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            productsParams.request.sortModel.push({
                colId: 'productConfigurationName',
                sort: 'asc',
            })
            const productsUrl = CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS.replace('{supplierId}', supplierId)
            this.addJob(productsUrl)
            this.productsLoading = true
            this.$api.post(productsUrl, productsParams).then(({ data }) => {
                let supplierProducts = data.data.items
                supplierProducts.map((product, i) => { product.indexPosition = i; return product })
                this.$set(this, 'supplierProducts', supplierProducts)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting supplier products failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(productsUrl)
                this.productsLoading = false
            })
        },
    },
    created () {
        this.handleDialogOpened()
        const suppliersParams = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
        suppliersParams.request.sortModel.push({
            colId: 'supplierName',
            sort: 'asc',
        })
    },
}
</script>
<style lang="scss" scoped>
    .pretty-select-error {
        position: absolute;
        transform: translate(0, -100%);
        font-size: 12px;
        letter-spacing: .01em;
    }
</style>
