<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Factors
            <template slot="actions">
                <mercur-button v-if="isAllowedTo('SupplierCentral/addSupplierFactor')" @click="openSupplierFactorConfiguration(null)" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    Add factor configuration
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            v-if="isAllowedTo('SupplierCentral/getAllSupplierFactors')"
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            ref="grid"
            :quickSearch="filters.search"
        ></data-table>
        <factor-configuration-popup
            :dialog-active.sync="factorPopupActive"
            :factor-details="factorDetails"
            @updated="$refs.grid.refreshGrid()"
            :available-service-levels="availableServiceLevels"
            :available-suppliers="availableSuppliers"
        ></factor-configuration-popup>
        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            <div slot="header">
                <h2 class="font-weight-normal">Are you sure you want to remove this factor configuration?</h2>
            </div>
            <div slot="default">
                It is not possible to recover this factor configuration after deleting.
            </div>
            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="deleteConfirmationDialogActive = false">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="deleteExtraDays(factorDetails)">Delete</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import FactorConfigurationPopup from '../../components/factors/FactorConfigurationPopup'

export default {
    name: 'FactorsOverview',
    components: { FactorConfigurationPopup, DataTable, GridHeader },
    data () {
        return {
            factorPopupActive: false,
            factorDetails: {},
            deleteConfirmationDialogActive: false,
            availableServiceLevels: null,
            availableSuppliers: null,
            options: {
                columns: {
                    'Supplier': {
                        field: 'supplierName',
                        sortable: true,
                    },
                    'ProductHash': {
                        field: 'productHash',
                    },
                    'Service Level': {
                        field: 'serviceLevelId',
                        valueGetter: ({ data }) => {
                            if (!data.serviceLevelId) {
                                return ''
                            }
                            if (!this.availableServiceLevels) {
                                return data.serviceLevelId
                            }

                            const serviceLevel = this.availableServiceLevels.find(serviceLevel => serviceLevel.serviceLevelId === data.serviceLevelId)
                            if (!serviceLevel) {
                                return data.serviceLevelId
                            }

                            return serviceLevel.serviceLevelName
                        },
                    },
                    'Country': {
                        field: 'countryCode',
                    },
                    'Factor': {
                        field: 'supplierFactor',
                    },
                    'supplierFactorId': {
                        field: 'supplierFactorId',
                        hide: true,
                    },
                    'supplierId': {
                        field: 'supplierId',
                        hide: true,
                    },
                    'serviceLevelId': {
                        field: 'serviceLevelId',
                        hide: true,
                    },
                    'productHash': {
                        field: 'productHash',
                        hide: true,
                    },
                    'countryCode': {
                        field: 'countryCode',
                        hide: true,
                    },
                    'supplierFactor': {
                        field: 'supplierFactor',
                        hide: true,
                    },

                },
                actions: [
                    {
                        icon: 'fas fa-edit',
                        text: 'Edit',
                        hide: !this.isAllowedTo('SupplierCentral/updateSupplierFactor'),
                        onClick: ({ data }) => this.openSupplierFactorConfiguration(data),
                    },
                    {
                        icon: 'fas fa-trash',
                        text: 'Delete',
                        hide: !this.isAllowedTo('SupplierCentral/removeSupplierFactor'),
                        onClick: ({ data }) => this.openDeleteConfirmDialog(data),
                    },
                ],

                quickSearchColumns: ['supplierFactorId', 'supplierId', 'serviceLevelId', 'productHash', 'countryCode', 'supplierFactor'],
            },

            url: CONFIG.API.ROUTES.FACTORS.OVERVIEW,
            filters: {
                search: '',
            },
        }
    },

    methods: {
        openSupplierFactorConfiguration (params = null) {
            this.factorDetails = params || {
                supplierId: null,
                serviceLevelId: null,
                countryCode: null,
                supplierFactor: 1,
            }
            this.factorPopupActive = true
        },
        openDeleteConfirmDialog (data) {
            this.factorDetails = data
            this.deleteConfirmationDialogActive = true
        },
        deleteExtraDays () {
            this.factorPopupActive = false
            this.loading = true
            const jobName = JSON.stringify(this.localConfiguration)

            this.addJob(jobName)
            const url = CONFIG.API.ROUTES.FACTORS.REMOVE
                .replace('{supplierFactorId}', this.factorDetails.supplierFactorId)

            this.$api.post(url, this.factorDetails).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Factor configuration configuration deleted.`,
                })
                this.$refs.grid.refreshGrid()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Deleting Factor configuration failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },
    },
    created () {
        if (!this.isAllowedTo('SupplierCentral/getAllServiceLevels', 'SupplierCentral/getSuppliersByParentId')) {
            this.emitPermissionErrorUserIsNotAllowedTo('get list of all suppliers and service levels')
            return
        }

        this.$store.dispatch('carriers/fetchAllServiceLevels').then(data => {
            this.availableServiceLevels = data.data.items
            if (this.$refs.grid && this.$refs.grid.gridApi) {
                this.$refs.grid.gridApi.refreshCells()
            }
        })

        const suppliersUrl = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
        this.addJob(suppliersUrl)
        const suppliersParams = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
        suppliersParams.request.sortModel.push({
            colId: 'supplierName',
            sort: 'asc',
        })
        this.$api.post(suppliersUrl, suppliersParams).then(({ data }) => {
            this.$set(this, 'availableSuppliers', data.data.items)
        }).finally(() => {
            this.finishJob(suppliersUrl)
        })
    },
}
</script>
